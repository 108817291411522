














































































import { Filter, ModuleQuestion } from '@app/models';
import { BlockElement, Reference } from '@bcase/module-editor';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import { ModuleModule } from '../../store/modules/module-module';

@Component
export default class DialogFilterManagement extends Vue {
  @Prop({ type: Object })
  public filter!: Filter;

  public active = true;
  public admin = false;
  public element: Filter['element'] = { id: '', question: '', type: '' };
  public label = '';
  public module: Filter['module'] = { id: '', name: '' };
  public target: string[] = [];

  private store = getModule(ModuleModule);

  public get modules() {
    return this.store.all
      .filter(m =>
        m.category.split('+').some(c => this.target.some(t => c.startsWith(t)))
      )
      .sort((a, b) => a.metadata.order - b.metadata.order);
  }

  public get questions() {
    if (!this.module.id) return [];

    return this.store.blocks.reduce((acc, block) => {
      const blockId = `${block.id}@${block.version}`;
      for (const element of block.elements) {
        if (!('question' in element)) continue;

        const value = this.getValue(element);
        if (!value) continue;

        acc.push({
          block: blockId,
          id: element.id,
          label: element.question,
          type: element.type,
          value,
        });
      }

      return acc;
    }, [] as { block: string; id: string; label: string; type: string; value: any }[]);
  }

  async created() {
    this.active = this.filter.active;
    this.admin = this.filter.admin;
    this.label = this.filter.label;
    this.module = this.filter.module;
    this.element = this.filter.element;
    this.target = [...this.$targetTabs];
    this.watchModuleId();
  }

  @Watch('module.id')
  public async watchModuleId() {
    const ref = this.toRef(this.module.id);
    await this.store.bind(ref);

    if (!this.questions.find(el => el.id === this.element.id))
      this.element = { id: '', question: '', type: '' };
  }

  public discard() {
    const dialog = this.$el as HTMLBceDialogElement;
    dialog.hide();
    this.$emit('discard');
  }

  public setElement(id: string) {
    const question = this.questions.find(q => q.id === id);
    if (!question) return;

    const { label, type, value } = question;
    this.element = { id, question: label, type, value };
  }

  public setModule(id: string) {
    const ref = this.toRef(id);
    const module = ref && this.store.find(ref);
    this.module = { id, name: (module && module.name) || '' };
  }

  public async submit() {
    const form = this.$el.querySelector('bce-dialog');
    const errors = form && (await (form as any).validate(true));
    if (errors && errors.length) return;

    const submit: Filter = {
      ...this.filter,
      active: this.active,
      admin: this.admin,
      label: this.label,
      module: this.module,
      element: this.element,
    };

    this.$emit('submit', submit);
  }

  private getValue(element: BlockElement) {
    let value;

    if ('options' in element) value = element.options;
    else if ('rows' in element)
      value = { rows: element.rows, columns: element.columns };
    else if ('step' in element)
      value = { min: element.min, max: element.max, step: element.step };

    return value || null;
  }

  private toRef(moduleId: string): Reference | undefined {
    const [id, version] = moduleId.split('@');
    return id && version ? { id, version } : undefined;
  }
}
