









































































import { User, ModuleQuestion, Filter } from '@app/models';
import { debounce } from '@bcase/core';
import { BlockElement } from '@bcase/module-editor';
import Vue from 'vue';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';

import DialogFilterManagement from '../components/3-dialogs/dialog-filter-management.vue';
import { ResultModule } from '../store/modules/result-module';

@Component({
  components: { DialogFilterManagement },
})
export default class FilterManagement extends Vue {
  public filter: Filter | null = null;

  private result = getModule(ResultModule);

  public get filters() {
    return this.result.availableFilters;
  }

  public get fields(): Array<any> {
    return [
      {
        key: 'icon',
        label: '',
        thClass: 'th-actions',
        tdClass: 'td-actions',
      },
      {
        key: 'active',
        label: this.$t('active'),
        formatter: (value: boolean) => this.$t(value),
      },
      {
        key: 'admin',
        label: this.$t('admin'),
        formatter: (value?: boolean) => this.$t(value || false),
      },
      {
        key: 'label',
        label: this.$t('label'),
      },
      {
        key: 'module.name',
        label: this.$t('module'),
      },
      {
        key: 'element.question',
        label: this.$t('question'),
      },
      {
        key: 'actions',
        label: '',
        thClass: 'th-actions',
        tdClass: 'td-actions',
      },
    ];
  }

  public async created() {
    await this.result.bind();
  }

  public async dialogSave(data: Filter) {
    const replace = !!this.filters.find(filter => filter.id === data.id);
    const filters = replace
      ? this.filters.map(f => (f.id === data.id ? data : f))
      : [...this.filters, data];

    await this.saveFilters(filters);
    this.dialogDiscard();
  }

  public dialogDiscard() {
    this.filter = null;
  }

  public filterAdd() {
    this.filter = {
      active: true,
      admin: false,
      element: { id: '', question: '', type: '' },
      id: this.$firebase.generateId(),
      label: '',
      module: { id: '', name: '' },
    };
  }

  public filterEdit(filter: Filter) {
    this.filter = filter;
  }

  public async filterMoveDown(filter: Filter, index: number) {
    const filters = [...this.filters];
    filters.splice(index, 1);
    filters.splice(index + 1, 0, filter);
    await this.saveFilters(filters);
  }

  public async filterMoveUp(filter: Filter, index: number) {
    const filters = [...this.filters];
    filters.splice(index, 1);
    filters.splice(index - 1, 0, filter);
    await this.saveFilters(filters);
  }

  public async filterRemove(filter: Filter) {
    const filters = this.filters.filter(f => f.id !== filter.id);
    await this.saveFilters(filters);
  }

  public getIcon(type: BlockElement.Type) {
    return BlockElement.icon(type);
  }

  private async saveFilters(filters: Filter[]) {
    await this.$firebase.doc('app/filters').set({ filters }, { merge: true });
  }

  private async saveRespondentFilters(respondentFilters: string[]) {
    await this.$firebase
      .doc('app/filters')
      .set({ respondentFilters }, { merge: true });
  }
}
